import React, { Component, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch} from "react-redux";
import { getAboutData } from "../../../reducers/aboutSlice";
export default function AboutUs() {
    const dispatch = useDispatch();
    const aboutsuccessData = useSelector((state)=> state.about.success);
    const aboutcontentData = useSelector((state)=> state.about.content);
    const [about, setAbout] = useState({});
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        dispatch(getAboutData());
    }, []);

    useEffect(()=>{
        if(aboutcontentData && aboutsuccessData){
            setAbout(aboutcontentData.about);
            setLoading(false);
        }
    },[aboutsuccessData]);


    return (
        <section id="about" class="aboutus text-center">
            {!loading && (
            <div class="container">
            <h6>About us</h6>
            <h2>
                {/* We're building a world where all <br />women can thrive in tech.  */}
            {about.headtext}</h2>
            <p>
                {/* Google's Women Techmakers program provides visibility, community, and resources for women in technology. */}
                {about.subtext}
            </p>

            <div class="stay mt-4" style={{ backgroundImage: `url(${"Images/aboutbg.png"})` }}>
                <div class="row">
                    <div class="col-8 col-sm-6">
                        <div class="lestside">
                            <h3>Stay connected</h3>
                            <p>Follow Women Techmakers for the latest news and updates.</p>
                            <ul>
                                <li><a href={about.linkedinlink}><i class="bi bi-linkedin"></i></a></li>
                                <li><a href={about.ytlink}><i class="bi bi-youtube"></i></a></li>
                                <li><a href={about.instlink}><i class="bi bi-instagram"></i></a></li>
                                <li><a href={about.twitlink}><i class="bi bi-twitter-x"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-5">

                <div class="col-6 col-sm-3">
                    <div class="set">
                        <div class="img">
                            <img src="Images/abouticon1.png" alt="abouticon1" />
                        </div>
                        <h3>
                            {/* 2,674  */}
                            {about.ambassdors}</h3>
                        <p>Ambassadors</p>
                    </div>
                </div>

                <div class="col-6 col-sm-3">
                    <div class="set">
                        <div class="img">
                            <img src="Images/abouticon2.png" alt="abouticon1" />
                        </div>
                        <h3>
                            {/* 1,264  */}
                            {about.events}</h3>
                        <p>Events</p>
                    </div>
                </div>

                <div class="col-6 col-sm-3">
                    <div class="set">
                        <div class="img">
                            <img src="Images/abouticon3.png" alt="abouticon1" />
                        </div>
                        <h3>
                            {/* 1,353 */}
                            {about.speakengagements}
                            </h3>
                        <p>Speaking engagements </p>
                    </div>
                </div>

                <div class="col-6 col-sm-3">
                    <div class="set">
                        <div class="img">
                            <img src="Images/abouticon4.png" alt="abouticon1" />
                        </div>
                        <h3>
                            {/* 82,500  */}
                            {about.members}</h3>
                        <p>Members</p>
                    </div>
                </div>

            </div>
            <p class="mb-0 mt-3">
                {/* Since 2013, Women Techmakers Ambassadors have held thousands of global tech events, inspiring and connecting women in tech. */}

                {about.text}
            </p>
            </div>
            )}
         
        </section>
    );
};

