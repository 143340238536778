import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { freeRoutes } from './Routes';
// import AuthRoute from './Authroute';

function App() {
  return (
    <div >
      <BrowserRouter basename={'/'}>
        <Routes >

          {freeRoutes.length > 0 && freeRoutes.map((item, i) => (
            <Route exact path={item.path} element={item.component} />
          ))}

          {/* {vendorRoutes.length > 0 && vendorRoutes.map((item, i) => (
            <Route exact path={item.path} element={<AuthRoute element={item.component} />} />
          ))} */}



        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
