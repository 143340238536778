import { getaboutdataapi, editaboutdataapi } from "./api";
import { getsubsriberdataapi, postsubsriberapi } from "./api";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const postSubscriber = createAsyncThunk('subscribers/edit', async (formData, { rejectWithValue }) => {
  try {
    const response = await postsubsriberapi(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});



const subscriberSlice = createSlice({
  name: 'subscribers',
  initialState: {
    submitting: false,
    error: null,
    success: false,
    content: [],
    createsuccess: false
  },
  reducers: {
    clearsubStatus: state => {
      state.success=false;
      state.error = null;
      state.submitting = false;
      state.createsuccess = false;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(postSubscriber.pending, state => {
        state.submitting = true;
        state.error = null;
        state.createsuccess = false;
      })
      .addCase(postSubscriber.fulfilled, (state,action) => {
        state.submitting = false;
        state.createsuccess=true;
        state.error = null;
      })
      .addCase(postSubscriber.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.createsuccess = false;
      });
  },
});
export const { clearsubStatus } = subscriberSlice.actions;
export default subscriberSlice.reducer;
