import { combineReducers } from '@reduxjs/toolkit';
import aboutSlice from './reducers/aboutSlice';
import storySlice from './reducers/storySlice';
import singlestorySlice from './reducers/singlestorySlice'
import blogsSlice from './reducers/blogsSlice';
import singleblogSlice from './reducers/singleblogSlice';
import subscriberSlice from './reducers/subscriberSlice';
import staticontentSlice from './reducers/staticontentSlice';
const rootReducer = combineReducers({
    about: aboutSlice,
    story: storySlice,
    singlestory: singlestorySlice,
    blogs: blogsSlice,
    singleblog: singleblogSlice,
    subscribers: subscriberSlice,
    staticcontent: staticontentSlice,
});

export default rootReducer;