import { getaboutdataapi } from "./api";

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getAboutData = createAsyncThunk('about/get', async (_, { rejectWithValue }) => {
  try {
    const response = await getaboutdataapi();
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});




const aboutSlice = createSlice({
  name: 'about',
  initialState: {
    submitting: false,
    error: null,
    success: false,
    content: [],
  },
  reducers: {
    clearStatus: state => {
      state.success=false;
      state.error = null;
      state.submitting = false;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAboutData.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getAboutData.fulfilled, (state,action) => {
        state.submitting = false;
        state.success=true;
        state.content = action.payload;
      })
      .addCase(getAboutData.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      });
  },
});
export const { clearStatus } = aboutSlice.actions;
export default aboutSlice.reducer;

