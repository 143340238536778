import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getsingleblogapi } from "./api";
export const getSingleBlog = createAsyncThunk('singleblog/get', async (formData, { rejectWithValue }) => {
  try {
    const response = await getsingleblogapi(formData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


const singleblogSlice = createSlice({
  name: 'singleblog',
  initialState: {
    submitting: false,
    error: null,
    success: false,
    content: [],
  },
  reducers: {
    clearsinblogStatus: state => {
      state.success=false;
      state.error = null;
      state.submitting = false;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getSingleBlog.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getSingleBlog.fulfilled, (state,action) => {
        state.submitting = false;
        state.success=true;
        state.content = action.payload;
      })
      .addCase(getSingleBlog.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      });
     
  },
});
export const { clearsinblogStatus } = singleblogSlice.actions;
export default singleblogSlice.reducer;