import { useParams } from "react-router-dom"
import { getSingleBlog, clearsinblogStatus } from "../../../reducers/singleblogSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Header from "../Header";
import Footer from "../Footer";
export default function BlogPage(){
    const imageurl = 'https://api.liivecity.com/uploads';
    const {id} = useParams();
    const dispatch = useDispatch();
    const [singleblog, setSingleBlog] = useState("");
    const [loading, setLoading] = useState(false);
    const singleblogsuccess = useSelector((state)=> state.singleblog.success);
    const singleblogData = useSelector((state)=> state.singleblog.content);
    useEffect(()=>{
        if(id){
          setLoading(true);
          dispatch(getSingleBlog({id}))
        }
      },
    [id]);
    useEffect(()=>{
        if(singleblogsuccess && singleblogData){
            setSingleBlog(singleblogData.blog);
            dispatch(clearsinblogStatus());
            setLoading(false);
        }
    },[singleblogsuccess]);
    return(
        <>
        <Header />
        <section id="singleblog" class="ourblog text-center">
            <div class="container">
                {!loading && (
                    <>
                <h2 class="mb-3 mb-lg-5">{singleblog.title}</h2>
                   <div class="row">
                    <div class="col-md-6 text-start oddr">
                        <h6><i class="bi bi-clock"></i> 
                        {moment(singleblog.createdAt).format(' MMMM D, YYYY').toUpperCase()}</h6>
                        <h4> {singleblog.title}</h4>
                        <p dangerouslySetInnerHTML={{ __html: singleblog.content}} style={{display: 'block'}}>
                            
                        </p>
                    </div>
                    <div class="col-md-6 ">
                        {/* <img src="Images/gemma.png" alt="gemma" /> */}
                        <img src={`${imageurl}/${singleblog.image}`} alt="latest" class="w-100" />
                    </div>
                </div> 
                    
                    </>
                )}
                
            </div>
        </section>
        <Footer />
        </>
    )
}