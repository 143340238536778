import React, { useEffect, useState } from "react";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { useLocation } from "react-router-dom";

const Location = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const paramLat = query.get('lat');
    const paramLng = query.get('lng');
    const [loc, setLoc] = useState({ lat: "", lng: "" });

    useEffect(() => {
        center();

    }, [paramLat, paramLng]);
    // Center coordinates for the map
    const mapStyles = {
        width: '100%',
        height: '100vh',
        
    };

    const center = () => {
        setLoc({
            lat: paramLat,
            lng: paramLng,
        });

    };
    return (
            
                    <Map 
                        google={window.google}
                        zoom={14}
                        style={mapStyles}
                        initialCenter={loc}
                        center={loc}
                    >
                        <Marker position={loc} />
                    </Map>
    );
};

export default GoogleApiWrapper({
    apiKey: 'AIzaSyCZUxf8l8O-ORcSoCYrbSG3SpdE6g086R4'
})(Location);
