import { useState, useEffect } from "react";
import { getStaticContent, clearstatconStatus} from '../../../reducers/staticontentSlice';
import { useDispatch, useSelector } from 'react-redux';
import Header from "../Header";
import Footer from "../Footer";
export default function PrivacyPolicyPage(){
    const dispatch = useDispatch();
    const staticsuccess = useSelector((state)=> state.staticcontent.success);
    const staticcontent = useSelector((state)=> state.staticcontent.content);
    const [loading, setLoading]= useState(false);
    const [staticdata, setStaticData] = useState("");
    useEffect(()=>{
        const data = { type: 'privacypolicy' };
        dispatch(getStaticContent(data))
    },[]);
    useEffect(()=>{
        if(staticsuccess && staticcontent){
            setStaticData(staticcontent.data.content);
            dispatch(clearstatconStatus());
        }
    },[staticsuccess])
    return(
        <>
        <Header/>
        <section className="terms">
        <div className="container ">
        <h2> Privacy Policy</h2>
        {!loading && (
        <div dangerouslySetInnerHTML={{ __html: staticdata }}></div>
        )}
        </div>
        </section>
        <Footer />
        </>
    )
}

