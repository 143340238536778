import React, { Component, useEffect, useState, useRef } from "react";
import { getblogs } from "../../../reducers/blogsSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import { Link } from "react-router-dom";
export default function Latest() {
    const imageurl = 'https://api.liivecity.com/uploads';

    
    const dispatch = useDispatch();
    const blogsuccessData = useSelector((state)=> state.blogs.success);
    const blogcontentData = useSelector((state)=> state.blogs.content);
    const [blogs, setBlogs] = useState({});
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        dispatch(getblogs());
    }, []);
    useEffect(()=>{
        if(blogsuccessData && blogcontentData){
            const filteredBlogs = blogcontentData.blogs.slice(1);
            setBlogs(filteredBlogs);
        }
    },[blogsuccessData]);


    return (
        <section class="latestpost text-center">
            <div class="container">

                <div class="inletest">
                    <h2>Latest Post</h2>
                    {!loading && (
                    <div class="row">
                        {blogs.length>0 && blogs.map((ele)=> (
                            <>
                            <div class="col-sm-6 col-lg-3 mb-3">
                            <Link to={`/blog/${ele._id}`}>
                            <div class="img" 
                            style={{ backgroundImage: `url(${imageurl}/${ele.image})`}} 
                           
                            // style={{ backgroundImage: `url(${"Images/letest1.png"})` }}
                            
                            ></div>
                            <h6>
                                {/* Introducing the Google Developer Program: Unlock... */}
                                {ele.title}
                                </h6>
                            <p><i class="bi bi-clock"></i> 
                            {moment(ele.createdAt).format(' MMMM D, YYYY').toUpperCase()}
                            </p>
                            </Link>
                        </div>
                            </>
                        ))}
                        

                        {/* <div class="col-sm-6 col-lg-3 mb-3">
                            <div class="img" style={{ backgroundImage: `url(${"Images/letest2.png"})` }}></div>
                            <h6>Introducing the Google Developer Program: Unlock...</h6>
                            <p><i class="bi bi-clock"></i> JUN 03, 2024</p>
                        </div>

                        <div class="col-sm-6 col-lg-3 mb-3">
                            <div class="img" style={{ backgroundImage: `url(${"Images/letest3.png"})` }}></div>
                            <h6>Introducing the Google Developer Program: Unlock...</h6>
                            <p><i class="bi bi-clock"></i> JUN 03, 2024</p>
                        </div>

                        <div class="col-sm-6 col-lg-3 mb-3">
                            <div class="img" style={{ backgroundImage: `url(${"Images/letest4.png"})` }}></div>
                            <h6>Introducing the Google Developer Program: Unlock...</h6>
                            <p><i class="bi bi-clock"></i> JUN 03, 2024</p>
                        </div> */}
                    </div>
                    )}
                </div>
            </div>
        </section>

    );
};

