import React, { Component, useEffect, useState, useRef } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { getStory } from "../../../reducers/storySlice";
import { useDispatch, useSelector } from "react-redux";
import { getAboutData, clearStatus } from "../../../reducers/aboutSlice";
export default function Stories() {
    const dispatch = useDispatch();
    const aboutsuccessData = useSelector((state)=> state.about.success);
    const aboutcontentData = useSelector((state)=> state.about.content);
    const [about, setAbout] = useState({});
    useEffect(() => {
        dispatch(getAboutData());
    }, []);

    useEffect(()=>{
        if(aboutcontentData && aboutsuccessData){
            setAbout(aboutcontentData.about);
            setLoading(false);
        }
    },[aboutsuccessData]);
    const storysuccessData = useSelector((state)=> state.story.success);
    const storycontentData = useSelector((state)=> state.story.content);
    const [story, setStory] = useState({});
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        dispatch(getStory());
    }, []);

    useEffect(()=>{
        if(storycontentData && storysuccessData){
            setStory(storycontentData.stories);
            setLoading(false);
        }
    },[storysuccessData]);

    const homeOptions = {
        loop: true,
        autoplay: false,
        autoplayTimeout: 5000,
        margin: 20,
        responsiveClass: true,
        responsive: {
            0: { items: 1.5, nav: true },
            576: { items: 2.5, nav: true },
            768: { items: 2.5, nav: true },
            992: { items: 2.5, nav: true },
            1400: { items: 3.5, nav: true },
            1600: { items: 3.6, nav: true }
        }
    };


    return (
        <section id="community" class="Stories">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-4 col-xl-3">
                        <div class="community">
                            <h2>Stories from our community</h2>
                            <p>
                                {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. */}
                                {about.storysubtitle}

                            </p>
                        </div>
                    </div>
                    <div class="col-md-8 col-xl-9">
                        {/* <div class="owl-carousel owl-theme Storiescarousel">

                            <div class="item">
                                <div class="set">
                                    <img src="/staging/splusfront/Images/scriptimg.png" alt="scriptimg" />
                                    <h5>Meet Nhasala, Women...</h5>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                            </div>

                            <div class="item">
                                <div class="set">
                                    <img src="/staging/splusfront/Images/scriptimg.png" alt="scriptimg" />
                                    <h5>Meet Nhasala, Women...</h5>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                            </div>

                            <div class="item">
                                <div class="set">
                                    <img src="/staging/splusfront/Images/scriptimg.png" alt="scriptimg" />
                                    <h5>Meet Nhasala, Women...</h5>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                            </div>

                            <div class="item">
                                <div class="set">
                                    <img src="/staging/splusfront/Images/scriptimg.png" alt="scriptimg" />
                                    <h5>Meet Nhasala, Women...</h5>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                            </div>

                        </div> */}

                        <OwlCarousel class="owl-carousel owl-theme Storiescarousel" {...homeOptions}>
                            {!loading && story.length> 0 && story.map((ele)=>(
                                <div class="item">
                                <div class="set">
                                    {/* <img src="Images/scriptimg.png" alt="scriptimg" /> */}
                                    <iframe width="275" height="200"
                                    src={ele.ytlink}>
                                    </iframe>
                                    <h5>
                                        {/* Meet Nhasala, Women...  */}
                                        {ele.author} </h5>
                                    <p>

                                        {ele.description}
                                    </p>
                                </div>
                            </div>
                            ))}
                            
                            {/* <div class="item">
                                <div class="set">
                                    <img src="Images/scriptimg.png" alt="scriptimg" />
                                    <h5>Meet Nhasala, Women...</h5>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                            </div>
                            <div class="item">
                                <div class="set">
                                    <img src="Images/scriptimg.png" alt="scriptimg" />
                                    <h5>Meet Nhasala, Women...</h5>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                            </div>
                            <div class="item">
                                <div class="set">
                                    <img src="Images/scriptimg.png" alt="scriptimg" />
                                    <h5>Meet Nhasala, Women...</h5>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                            </div> */}
                        </OwlCarousel>


                    </div>
                </div>
            </div>
        </section>

    );
};

