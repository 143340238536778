import axios from 'axios';

// const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

// const API_BASE_URL = "https://api.liivecity.com";
const API_BASE_URL = "https://api.liivecity.com";


export const getaboutdataapi = async () =>{
    try {
      const response = await axios.get(`${API_BASE_URL}/api/about`)   
      return response;
    } catch (error) {
      throw error;
    }
  }

  export const getstoryapi = async ()=>{
    try {
      const response = await axios.get(`${API_BASE_URL}/api/getstory`);
      return response;
    } catch (error) {
      throw error;
    }
  }
  export const getsinglestoryapi = async (formData)=>{
    try {
      const {id} = formData;
      const response = await axios.get(`${API_BASE_URL}/api/storybyid/${id}`)
      return response;
    } catch (error) {
      throw error;
    }
  }


  export const getallblogapi = async ()=>{
    try {
      const response = await axios.get(`${API_BASE_URL}/api/getblogs`);
      return response;
    } catch (error) {
      throw error;
    }
  }
  export const getsingleblogapi = async (formData)=>{
    try {
      const {id} = formData;
      const response = await axios.get(`${API_BASE_URL}/api/blogbyid/${id}`)
      return response;
    } catch (error) {
      throw error;
    }
  }


  export const postsubsriberapi = async (formData)=>{
    try {
      const response = await axios.post(`${API_BASE_URL}/api/subscriber/new`, formData);
      return response;
    } catch (error) {
      throw error;
    }
  }

  export const getstaticondataapi = async (formData) =>{
    try {
      const response = await axios.get(`${API_BASE_URL}/api/staticcontentbytype`, { params: {
        ...formData
       } })   
      return response;
    } catch (error) {
      throw error;
    }
  }

