import React, { Component, useEffect, useState, useRef } from "react";
import { getblogs } from "../../../reducers/blogsSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import { Link } from "react-router-dom";

export default function Blogs() {
    const imageurl = 'https://api.liivecity.com/uploads';

    
    const dispatch = useDispatch();
    const blogsuccessData = useSelector((state)=> state.blogs.success);
    const blogcontentData = useSelector((state)=> state.blogs.content);
    const [blogs, setBlogs] = useState({});
    const [latestBlog, setlatestBlog] = useState({});
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        dispatch(getblogs());
    }, []);
    useEffect(()=>{
        if(blogsuccessData && blogcontentData){
            setlatestBlog(blogcontentData.latestBlog);
        }
    },[blogsuccessData]);
    return (
        <section id="blogs" class="ourblog text-center">
            <div class="container">
                <h2 class="mb-3 mb-lg-5">Our Blog</h2>
                {!loading && (
                    <>
                   <div class="row">
                    <div class="col-md-6 text-start oddr">
                        <h6><i class="bi bi-clock"></i> 
                        {/* MAY 14, 2024  */}
                        {moment(latestBlog.createdAt).format(' MMMM D, YYYY').toUpperCase()}</h6>
                        <h4>
                            {/* Introducing PaliGemma, Gemma 2, and an Upgraded Responsible AI Toolkit  */}
                            {latestBlog.title}</h4>
                        <p dangerouslySetInnerHTML={{ __html: latestBlog.content}} className="homeblog">
                            {/* At Google, we believe in the power of collaboration and open research to drive innovation, and we're grateful to see Gemma embraced by the community with millions of downloads within a few short months of its launch. */}
                        </p>
                        <Link to={`/blog/${latestBlog._id}`} class="readmore">Read More</Link>
                    </div>
                    <div class="col-md-6 ">
                        {/* <img src="Images/gemma.png" alt="gemma" /> */}
                        <img src={`${imageurl}/${latestBlog.image}`} alt="latest" 
                            class="w-100" />
                    </div>
                </div> 
                    
                    </>
                )}
                
            </div>
        </section>


    );
};

