import Home from "../Components/Pages/Home";
import Location from "../Components/Pages/Home/Location";
import BlogPage from "../Components/Pages/Blog";
import PrivacyPolicyPage from "../Components/Pages/PrivacyPolicy";
import TermsandconditionsPage from "../Components/Pages/Terms&Conditions";

const freeRoutes = [
    {
        path: "/",
        component: <Home />
    },
    {
        path: "/LiveCityLocation",
        component: <Location />
    },
    {
        path: "/blog/:id",
        component: <BlogPage/>
    },
    {
        path: '/privacypolicy',
        component: <PrivacyPolicyPage />
    },
    {
        path: '/terms&conditions',
        component: <TermsandconditionsPage />
    }

];




export { freeRoutes };