import React, { Component, useEffect, useState } from "react";
import { FacebookShareButton, FacebookIcon, TwitterIcon, TwitterShareButton, WhatsappShareButton, WhatsappIcon } from 'react-share';


export default function Share({ setSharePop, shareUrl }) {

    useEffect(() => {
    }, []);


    return (
        // <div class="modal fade show" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ 'display': 'block' }}>
        //     <div class="modal-dialog modal-dialog-centered" role="document">
        //         <div class="modal-content" style={{ 'border': 'none', 'background': 'transparent' }}>
        //             <div class="modal-body p-0 ">
        <div class="share_option m-0 w-100">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => setSharePop(false)}>
                <span aria-hidden="true" >&times;</span>
            </button>
            <h1>Share Options</h1>
            <ul>
                <FacebookShareButton
                    url={shareUrl}

                // quote={shareUrl.link}
                // hashtag="#muo"
                // imageUrl={shareUrl}
                >
                    <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton
                    url={shareUrl}
                // quote={shareUrl.link}
                // hashtag="#muo"
                >
                    <TwitterIcon size={32} round />
                </TwitterShareButton>
                <WhatsappShareButton
                    // quote={shareUrl.link}
                    url={shareUrl}  // Use the link part of shareUrl
                    title={"Live City Location"}
                // hashtag="#muo"
                >
                    <WhatsappIcon size={32} round />
                </WhatsappShareButton>

            </ul>

        </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>

    )


}
