import React, { Component, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAboutData, clearStatus } from "../../../reducers/aboutSlice";
import { postSubscriber, clearsubStatus } from "../../../reducers/subscriberSlice";
export default function Footer() {
  const dispatch = useDispatch();
  const aboutsuccessData = useSelector((state)=> state.about.success);
  const aboutcontentData = useSelector((state)=> state.about.content);
  const subscriberpostsuccess = useSelector((state)=> state.subscribers.createsuccess);
  const subscribererrorData = useSelector((state)=> state.subscribers.error);
  const [about, setAbout] = useState({});
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };
  useEffect(() => {
      dispatch(getAboutData());
  }, []);
  const handleSubmit = () => {
    let data = new FormData();
    data.append('email', email);
    dispatch(postSubscriber(data));
  };
  useEffect(()=>{
      if(aboutcontentData && aboutsuccessData){
          setAbout(aboutcontentData.about);
          setLoading(false);
          dispatch(clearStatus());
      }

  },[aboutsuccessData]);
  useEffect(()=>{
    if(subscriberpostsuccess){
      alert('Subscribed successfully');
      setEmail('');
      dispatch(clearsubStatus());
    }
    if(subscribererrorData ){
      alert(subscribererrorData.data.message);
      setEmail('');
      dispatch(clearsubStatus());
    }
  },[subscriberpostsuccess,subscribererrorData ])
    return (

        <footer class="footer text-center">
        <div class="container">
          <a href="#">
            <img src="../Images/logoLIve.png" alt="icon" />
          </a>
          <br/>
          {!loading && (
          <ul>
          <li><a href={about.linkedinlink}><i class="bi bi-linkedin"></i></a></li>
          <li><a href={about.ytlink}><i class="bi bi-youtube"></i></a></li>
          <li><a href={about.instlink}><i class="bi bi-instagram"></i></a></li>
          <li><a href={about.twitlink}><i class="bi bi-twitter-x"></i></a></li>
          </ul>
          )}
         
      
          <div class="form">
            <h4>Join our community</h4>
            <form  onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
              <input type="email" placeholder="Enter your Email"  value={email} 
          onChange={handleInputChange}  />
              <button type="submit">GO</button>
            </form>
          </div>
      
        </div>
      
        <p class="enddiv">2024 ©LiiveCity Google United Kingdom Terms Privacy</p>
      </footer>
    );
};

