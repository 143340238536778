import React, { Component, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

export default function Header() {

    useEffect(() => {

    }, []);

    return (

        <header class="main_header" id="myHeader">

        <div class="container">
            <div class="row">
                <div class="col-4 col-md-3">
                    <Link to ="/">
                    <img src="../Images/logoLIve.png" /></Link>
                </div>

                <div class="col-12 col-md-9">
                    <nav class="navbar navbar-expand-md">
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <i class="bi bi-list"></i>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarNav">
                            <ul class="navbar-nav">
                                <li class="nav-item">
                                    <a class="nav-link" href="#home">Home</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#about">About</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#blogs">Blogs</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link " href="#community">Community</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
                {/* <!-- <div class="col-8 col-md-3 text-center text-sm-end">
                    <a href="#" class="become"><i class="bi bi-people-fill"></i> <span>Become a Member</span></a>
                </div> --> */}

            </div>
        </div>

    </header>
    );
};

