import React, { Component, useEffect, useState, useRef } from "react";
import Globe from 'react-globe.gl';
import Share from "./Share";
import { useLocation } from "react-router-dom";


export default function GlobeView() {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    // const paramLat = query.get('lat');
    // const paramLng = query.get('lng');
    const [locations, setLocations] = useState([]);

    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipContent, setTooltipContent] = useState('');
    const [sharePop, setSharePop] = useState(false);
    const [url, setUrl] = useState("");
    const globeRef = useRef(null);

    // Check screen width and set initial viewPoint
    const isMobile = window.innerWidth < 756;

    const initialViewPoint = isMobile ? { lat: 0, lng: 0, altitude: 2.5 } : { lat: 0, lng: 0, altitude: 0.5 };
    const [viewPoint, setViewPoint] = useState(initialViewPoint);

    const fetchLocationData = async (latitude, longitude, type) => {
        try {
            const res = await getCityName(latitude, longitude, type);
            return {
                coordinates: [latitude, longitude],
                imageUrl: "Images/marker.jpg",
                type: type,
                address: res.address,
                country: res.country
            };
        } catch (error) {
            console.error(`Failed to fetch city name for ${type} location:`, error);
            return null;
        }
    };

    const updateLocations = async () => {
        try {
            const position = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            });

            const { latitude, longitude } = position.coords;

            const currentLocation = await fetchLocationData(latitude, longitude, "current");

            if (currentLocation) {
                setLocations([{
                    label: 'Your location',
                    ...currentLocation
                }]);

                setViewPoint({ lat: latitude, lng: longitude, altitude: 0.5 });



                // if (globeRef.current) {
                //     globeRef.current.pointOfView({ lat: latitude, lng: longitude }, 1000);
                // }

                // if (paramLat && paramLng) {
                //     const paramLocation = await fetchLocationData(paramLat, paramLng, "params");

                //     if (paramLocation) {
                //         setLocations(prevLocations => [
                //             ...prevLocations,
                //             {
                //                 label: "Other Location",
                //                 ...paramLocation
                //             }
                //         ]);

                //         setViewPoint(isMobile ? { lat: latitude, lng: longitude, altitude: 2.5 } : { lat: latitude, lng: longitude, altitude: 0.5 });


                //         // if (globeRef.current) {
                //         //     globeRef.current.pointOfView({ lat: paramLat, lng: paramLng }, 1000);
                //         // }
                //     }
                // }

                setDefaultTooltip(currentLocation);
            }
        } catch (error) {
            console.error("Geolocation error or data fetch error:", error);
        }
    };

    // const getGoogleMapImageUrl = (lat, lng, zoom = 15, size = '600x300') => {
    //     const apiKey = 'AIzaSyCZUxf8l8O-ORcSoCYrbSG3SpdE6g086R4'; // Replace with your Google Maps API key
    //     return `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=${zoom}&size=${size}&markers=color:red%7C${lat},${lng}&key=${apiKey}`;
    // };

    // const getGoogleMapLink = (lat, lng) => {
    //     return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    // };

    const getGoogleMapImageUrl = (lat, lng, zoom = 15, size = '600x300') => {
        const apiKey = 'AIzaSyCZUxf8l8O-ORcSoCYrbSG3SpdE6g086R4'; // Replace with your Google Maps API key
        // const imageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=${zoom}&size=${size}&markers=color:red%7C${lat},${lng}&key=${apiKey}`;
        return `https://liivecity.com/LiveCityLocation?lat=${lat}&lng=${lng}`; // Replace with your site's URL

        // return { imageUrl, siteUrl };
    };


    const setDefaultTooltip = async (location) => {
        const address = location.address + ", " + location.country;
        // setUrl(`https://liivecity.com/?lat=${location.coordinates[0]}&lng=${location.coordinates[1]}`);
        // setUrl(<a href={`https://liivecity.com/?lat=${location.coordinates[0]}&lng=${location.coordinates[1]}`} >Click here</a>);

        const ImgUrl = await getGoogleMapImageUrl(location.coordinates[0], location.coordinates[1]);
        const quoteText = `Click here to view location: ${ImgUrl}`;
        setUrl(ImgUrl);
        // setUrl(`<a href=${ImgUrl}> Click here to view location</a>`);

    
        const content = (
            <div>
                <img src="Images/profile.png" alt="Location" style={{ width: '100px', height: '100px' }} />
                <p>{address}</p>
                {location.type === "current" && <button onClick={() => OpenShare()}>Share</button>}
            </div>
        );

        setTooltipContent(content);
        setTooltipPosition({ x: window.innerWidth / 2, y: window.innerHeight / 2 }); // Center the tooltip initially
        setShowTooltip(true);
    };

    useEffect(() => {
        updateLocations();
    }, []);

    useEffect(() => {
        if (globeRef.current) {
            globeRef.current.pointOfView(viewPoint, 1000);
        }
    }, [viewPoint]);



    const getCityName = async (latitude, longitude, type) => {
        const latlng = `${latitude},${longitude}`;

        try {
            const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng}&key=AIzaSyCZUxf8l8O-ORcSoCYrbSG3SpdE6g086R4`);
            if (!response.ok) {
                throw new Error('Geocoding request failed');
            }
            const data = await response.json();

            let address = null;
            let Country = null;

            address = await data.results[0].address_components.find(component => component.types.includes('administrative_area_level_1')) ? data.results[0].address_components.find(component => component.types.includes('administrative_area_level_1')).long_name : "Unknown Address";
            Country = await data.results[0].address_components.find(component => component.types.includes('country')) ? data.results[0].address_components.find(component => component.types.includes('country')).long_name : "Unknown Country";


            let dat = {
                address: address,
                country: Country
            }

            return dat;
        } catch (error) {
            console.error('Error fetching geocode data:', error.message);
        }
    };

    const OpenShare = () => {
        setSharePop(true);
        // setShowTooltip(false);
    }


    const handleGlobeImageError = () => {
        console.error('Failed to load globe image.');
    };

    // const handleLabelHover = async (event, d) => {
    //     console.log('d', event);
    //     await setShowTooltip(false);

    //     const mouseX = d.pageX;
    //     const mouseY = d.pageY;
    //     setTooltipPosition({ x: mouseX, y: mouseY });
    //     const address = event.address + ", " + event.country;
    //     // setUrl(`https://liivecity.com/?lat=${event.coordinates[0]}&lng=${event.coordinates[1]}`);
    //     setUrl(<a href={`https://liivecity.com/?lat=${event.coordinates[0]}&lng=${event.coordinates[1]}`} >Click here</a>);

    //     const content = (
    //         <div >
    //             <img src="Images/profile.png" alt="Location" style={{ width: '100px', height: '100px' }} />
    //             <p>{address}</p>
    //             {event.type === "current" &&
    //                 <button onClick={() => OpenShare()}>Share</button>
    //             }
    //             {/* onClick={() => window.open(shareUrl, '_blank')} */}
    //         </div>
    //     );

    //     setTooltipContent(content);
    //     setShowTooltip(true);

    // };


    return (

        <>



            <section class="banner" id="home" >


                <div class="globe-container">

                    <Globe
                        globeImageUrl="//unpkg.com/three-globe/example/img/earth-blue-marble.jpg"
                        backgroundColor="#111"
                        ref={globeRef}
                        pointOfView={viewPoint}
                        labelsData={locations}
                        labelLat={(d) => d.coordinates[0]}
                        labelLng={(d) => d.coordinates[1]}
                        labelSize={0}
                        labelDotRadius={1}
                        labelColor={() => 'red'}
                        labelText={(d) => d.label}
                        // onLabelClick={(event, d) => handleLabelHover(event, d)}
                        onGlobeImageError={handleGlobeImageError}

                    />
                    {showTooltip && (
                        <div
                            style={{
                                position: 'absolute',
                                left: tooltipPosition.x + 10, // Adjust as needed
                                top: tooltipPosition.y + 10, // Adjust as needed
                                background: 'white',
                                padding: '10px',
                                border: '1px solid black',
                                borderRadius: '5px',
                                boxShadow: '0 0 10px rgba(0,0,0,0.3)',
                                zIndex: 9999,
                            }}

                            class="location_popUp"
                        >
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowTooltip(false)}>
                                <span aria-hidden="true" >&times;</span>
                            </button>
                            {tooltipContent}
                        </div>
                    )}

                </div>
            </section>

            {sharePop === true &&
                <Share setSharePop={setSharePop} shareUrl={url} />
            }



        </>
    );
};

