import React, { Component, useEffect, useState, useRef } from "react";
import Header from "../Header";
import Footer from "../Footer";
import AboutUs from "./AboutUs";
import GlobeView from "./GlobeView";
import Stories from "./Stories";
import Blogs from "./Blogs";
import Latest from "./Latest";


export default function LiveCity() {

    useEffect(() => {

    }, []);


    return (

        <>
            <Header />
            <GlobeView />
            <AboutUs />
            <Stories />
            <Blogs />
            <Latest />
            <Footer />
        </>
    );
};

