import { getstoryapi} from "./api";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


export const getStory = createAsyncThunk('story/get', async (_, { rejectWithValue }) => {
  try {
    const response = await getstoryapi();
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

const storySlice = createSlice({
  name: 'story',
  initialState: {
    submitting: false,
    error: null,
    success: false,
    addsuccess: false,
    content: [],
    editsuccess: false,
    delsuccess: false
  },
  reducers: {
    clearStatus: state => {
      state.success=false;
      state.error = null;
      state.submitting = false;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getStory.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getStory.fulfilled, (state,action) => {
        state.submitting = false;
        state.success=true;
        state.content = action.payload;
      })
      .addCase(getStory.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      });
  },
});
export const { clearStatus } = storySlice.actions;
export default storySlice.reducer;